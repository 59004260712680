import "./TroisiemePage.css"
import vector2 from "../images/vector2.png"
import working from "../images/working.png"

function TroisiemePage() {
    return (
        <div className="container" id="TroisiemePage">
            <h2>Join us</h2>
            <h3>Join us if you want to be part of the journey. Join us
                if you believe in you, the power of collective intelligence
                and are passionate about shaping your future through
                informed, collaborative research.</h3>
            <div id="TroisiemePageButtons"><button>Fill the form</button><button>Contact us</button></div>

            <img id="TroisiemeVector" src={vector2} alt="vec"></img>
            <img id="TroisiemeWorking" src={working} alt="working"></img>
        </div>
    );
}

export default TroisiemePage;
