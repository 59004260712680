import React from 'react';
import ReactDOM from 'react-dom/client';
import PremierePage from './Componements/PremierePage';
import SecondPage from './Componements/SecondPage';
import TroisiemePage from './Componements/TroisiemePage';
import Footer from './Componements/footer';
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PremierePage />
    <SecondPage />
    <TroisiemePage />
    <Footer />
  </React.StrictMode>
);
