import "./PremierePage.css"
import testImg from "../images/testImg.png"
import goto from "../images/goto.png"

import logo_discord from "../images/discord.png"
import logo_twitter from "../images/twitter.png"
import logo_sm from "../images/sm.png"

import vector from "../images/vector.png"

let pages = [];
for (var i = 0; i < 4; i++)
    pages.push({
        source: "MEDIUM",
        title: "The Modular Future : What’s next with Celestia’s DA",
        link: "https://yoururl.com"
    });

function PremierePage() {
    return (
        <div className="container" id="PremierePage">
            <h1>ResearchDAO</h1>
            <div id="PremierePageReseaux">
                <img src={logo_discord} onClick={() => { window.open('https://yoururl.com', '_blank') }} alt="discord"></img>
                <img src={logo_twitter} alt="twitter"></img>
                <img src={logo_sm} alt="sm"></img>
            </div>
            <h2>Researchs</h2>
            <div id="PremierePageDocuments">
                {pages.map(function (object, i) {
                    return <div key={"doc_" + i}>

                        <img src={testImg} alt="Wait"></img>
                        <img src={goto} className="goto" alt="goto" onClick={() => { window.open(object.link, '_blank') }} ></img>
                        <p className="title">{object.source}</p>
                        <p>{object.title}</p>
                    </div>;
                })}
            </div>
            <img id="PremierePageVector" src={vector} alt="vec"></img>
        </div>
    );
}

export default PremierePage;
