import "./SecondPage.css"
import vector from "../images/thing.png"

function SecondPage() {
    return (
        <div className="container" id="SecondPage">
            <h2>About us</h2>
            <h3>ResearchDAO is a collective of independent researchers who unite
                their knowledge to conduct in-depth research on various protocols
                and web3 projects. Here, we delve into the intricacies of blockchain
                technologies, smart contracts, decentralized finance and emerging
                blockchain-powered trends. ResearchDAO emphasizes that it
                does not provide financial advice, focusing instead on sharing
                comprehensive research and insights.</h3>

            <img id="SecondPageVector" src={vector} alt="vec"></img>
        </div>

    );
}

export default SecondPage;
